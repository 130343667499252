<template>
    <div>
        <div
            class="tw-bg-gray-100 tw-border-b tw-border-gray-400 tw-p-2 tw-relative tw-text-blue-700"
        >
            <div class="d-flex justify-content-between">
                <div>
                    <b>{{ $t('payrollTemplate.userVariable') }}</b>
                </div>
                <div>
                    <a
                        href="#"
                        @click.prevent="collapse = collapse ? false : true"
                    >
                        <i class="fas fa-chevron-down" v-if="!collapse"></i>
                        <i class="fas fa-chevron-up" v-else></i>
                    </a>
                </div>
            </div>
        </div>
        <div id="varibleTable" v-show="!collapse">
            <div class="overflow-auto tw-h-96">
                <table class="table table-hover ui-table">
                    <thead>
                        <tr class="tw-top-0 tw-sticky">
                            <th style="width:19%">
                                {{ $t('payrollTemplate.variableName') }}
                            </th>
                            <th>{{ $t('payrollTemplate.description') }}</th>
                            <th style="width:19%">
                                {{ $t('payrollTemplate.type') }}
                            </th>
                        </tr>
                        <tr class="tw-top-0 tw-sticky">
                            <th>
                                <input
                                    v-model="filters.variableName"
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('payrollTemplate.filterVariableName')
                                    "
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('payrollTemplate.filterDescription')
                                    "
                                    v-model="filters.description"
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('payrollTemplate.filterType')
                                    "
                                    v-model="filters.dataType"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(variable, index) in filterResults"
                            :key="index"
                            @dblclick="addFieldTemplate(variable)"
                            class="tw-cursor-pointer"
                        >
                            <td>{{ variable.sv_name }}</td>
                            <td>{{ variable.display_name }}</td>
                            <td>{{ variable.data_type }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tw-bg-gray-100 tw-border-b tw-border-gray-400 tw-p-2 tw-relative tw-text-blue-700 tw-my-1"
        >
            <b>{{ $t('payrollTemplate.pageTitle') }}</b>
        </div>
        <div class="overflow-auto tw-h-96">
            <div class="table-ui-detail">
                <table>
                    <thead>
                        <tr>
                            <th>{{ $t('payrollTemplate.orderId') }}</th>
                            <th>{{ $t('payrollTemplate.displayName') }}</th>
                            <th>{{ $t('payrollTemplate.summary') }}</th>
                            <th>{{ $t('payrollTemplate.order') }}</th>
                            <th style="width:5%;" class="text-center">
                                {{ $t('payrollTemplate.action') }}
                            </th>
                        </tr>
                    </thead>
                    <draggable v-model="template_detail" tag="tbody">
                        <tr
                            v-for="(item, index) in template_detail"
                            :key="index"
                            class="tw-cursor-move"
                        >
                            <td valign="middle">
                                <p class="mt-2">{{ item.variable_name }}</p>
                            </td>
                            <td style="width:40%">
                                <input
                                    type="text"
                                    class="form-control only-border-bottom"
                                    v-model="item.display_name"
                                />
                            </td>
                            <td>
                                <select
                                    v-model="item.summary"
                                    class="form-control only-border-bottom"
                                >
                                    <option value=""></option>
                                    <option value="SUM">SUM</option>
                                    <option value="MIN">MIN</option>
                                    <option value="MAX">MAX</option>
                                    <option value="AVG">AVG</option>
                                    <option value="COUNT">COUNT</option>
                                </select>
                            </td>
                            <td>
                                <select
                                    v-model="item.order_by"
                                    class="form-control only-border-bottom"
                                >
                                    <option value=""></option>
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </td>
                            <td class="text-center" valign="middle">
                                <a
                                    href="#"
                                    class="mt-2"
                                    @click="removeRow(item)"
                                    v-tooltip="$t('payrollTemplate.remove')"
                                >
                                    <i
                                        class="fas fa-trash fa-2x text-danger"
                                    ></i>
                                </a>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
Array.prototype.moveIndex = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0])
    return this
}
import draggable from 'vuedraggable'
export default {
    name: 'variable-list',
    props: ['value'],
    components: {
        draggable
    },
    data () {
        return {
            template_detail: [],
            collapse: false,
            filters: {
                variableName: '',
                description: '',
                dataType: ''
            }
        }
    },
    computed: {
        filterResults () {
            return this.$store.state.payroll.payrollTemplate.userVariables
                .filter(
                    v =>
                        v.sv_name
                            .toLowerCase()
                            .indexOf(this.filters.variableName.toLowerCase()) !=
                        -1
                )
                .filter(
                    v =>
                        v.display_name
                            .toLowerCase()
                            .indexOf(this.filters.description.toLowerCase()) !=
                        -1
                )
                .filter(
                    v =>
                        v.data_type
                            .toLowerCase()
                            .indexOf(this.filters.dataType.toLowerCase()) != -1
                )
        }
    },
    methods: {
        addFieldTemplate (field) {
            this.template_detail.push({
                variable_name: field.sv_name,
                display_name: field.display_name,
                column_order: '',
                summary: '',
                order_by: ''
            })
        },
        removeRow (item) {
            this.template_detail.splice(this.template_detail.indexOf(item), 1)
        }
    },
    watch: {
        template_detail: function (value) {
            this.$emit('sort', value)
        },
        value: {
            handler (val) {
                this.template_detail = val
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
