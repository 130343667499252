<template>
    <div>
        <ts-page-title
            :title="$t('payrollTemplate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('payrollTemplate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click.prevent="addNew"
                    >{{ $t('addNew') }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('customer.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="created_by">
                    {{
                        row.created_by
                            ? row.created_by.employee
                                ? row.created_by.employee.employee_name_en
                                : row.created_by.user_name
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-warning tw-mr-2"
                        @click.prevent="designTemlate(row)"
                        v-tooltip="$t('payrollTemplate.designTemplate')"
                    >
                        <i class="fas fa-file-signature"></i>
                    </a>
                    <a
                        href=""
                        class="text-blue tw-mr-2"
                        @click="templateRowValue = row"
                        v-tooltip="$t('payrollTemplate.uploadTemplate')"
                    >
                        <label
                            for="uploadTemplate"
                            class="text-primary tw-cursor-pointer"
                        >
                            <i class="fas fa-file-upload"></i>
                        </label>
                    </a>
                    <a
                        href="#"
                        class="text-primary tw-mr-1"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                        placement="left"
                        :transfer="true"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <input
                type="file"
                id="uploadTemplate"
                ref="uploadTemplate"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="uploadTemplate"
                style="display:none"
            />
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('payrollTemplate.pageTitle')"
                width="80%"
            >
                <form-action
                    ref="form_action"
                    @close="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import * as FileDownload from 'downloadjs'
import { mapState } from 'vuex'
import FormAction from './form.vue'
import { trim, debounce } from 'lodash'
import { Errors } from 'form-backend-validation'

export default {
    name: 'payroll-template',
    components: {
        FormAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            templateRowValue: [],
            file: '',
            errors: new Errors(),
            showForm: false
        }
    },
    computed: {
        ...mapState('payroll/payrollTemplate', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.payroll.payrollTemplate.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/payrollTemplate/SET_SEARCH',
                    newValue
                )
                this.$store.commit('payroll/payrollTemplate/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('payrollTemplate.templateName'),
                    key: 'template_name',
                    sortable: true,
                    minWidth: 250
                },
                {
                    title: this.$t('payrollTemplate.templateType'),
                    key: 'template_type',
                    align: 'center',
                    sortable: true,
                    minWidth: 100
                },
                {
                    title: this.$t('payrollTemplate.payrollCycleNumber'),
                    key: 'payroll_cycle_number_text',
                    align: 'center',
                    sortable: true,
                    minWidth: 100
                },
                {
                    title: this.$t('payrollTemplate.createdBy'),
                    slot: 'created_by',
                    sortable: true,
                    minWidth: 100
                },
                {
                    title: this.$t('payrollTemplate.createdDate'),
                    key: 'created_date',
                    sortable: true,
                    align: 'right',
                    minWidth: 100
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 150
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/payrollTemplate/fetch', { ...attributes })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            this.$store.commit('payroll/payrollTemplate/SET_EDIT_DATA', record)
            this.showForm = true
            this.$refs.form_action.fetchVariable(record.payroll_cycle_number)
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit('payroll/payrollTemplate/SET_EDIT_DATA', {})
            this.$store.commit('payroll/payrollTemplate/SET_VARIABLE', [])
        },
        addNew () {
            this.$store.commit('payroll/payrollTemplate/SET_EDIT_DATA', {})
            this.$store.commit('payroll/payrollTemplate/SET_VARIABLE', [])
            this.showForm = true
            this.$refs.form_action.clearInput()
            this.$refs.form_action.fetchVariable()
        },
        async destroy (record) {
            if (
                await this.$confirmModal.danger(
                    this.$t('are_you_sure_to_delete')
                )
            ) {
                record._deleting = true
                this.$store
                    .dispatch(
                        'payroll/payrollTemplate/destroy',
                        record.template_id
                    )
                    .then(response => {
                        this.fetchData()
                        this.notice({ type: 'success', text: response.message })
                        record._deleting = false
                    })
                    .catch(error => {
                        this.notice({ type: 'error', text: error.message })
                        record._deleting = false
                    })
            }
        },
        designTemlate (record) {
            this.loading = true
            this.$store
                .dispatch('payroll/payrollTemplate/design', {
                    id: record.template_id,
                    override: record.is_override
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(errorData => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(
                            null,
                            new Uint8Array(errorData)
                        )
                    )
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async uploadTemplate () {
            this.file = this.$refs.uploadTemplate.files[0]
                ? this.$refs.uploadTemplate.files[0]
                : null
            if (this.file) {
                // if (
                //     await this.$confirmModal.danger(
                //         this.$t('payrollTemplate.areYouSureToUploadTemplate')
                //     )
                // ) {
                this.loading = true

                let formData = new FormData()
                formData.append('template_file', this.file)

                this.$store
                    .dispatch('payroll/payrollTemplate/uploadTemplate', {
                        id: this.templateRowValue.template_id,
                        data: formData
                    })
                    .then(response => {
                        this.notice({
                            type: 'success',
                            text: response.message
                        })
                        this.$refs.uploadTemplate.value = ''
                        this.loading = false
                        this.templateRowValue = []
                    })
                    .catch(error => {
                        this.errors = new Errors(error.errors)
                        this.$toasted.error(
                            this.errors.has('template_file')
                                ? this.errors.first('template_file')
                                : error.message
                        )
                        this.$refs.uploadTemplate.value = ''
                        this.loading = false
                        this.templateRowValue = []
                    })
            }
            // } else {
            //     this.$Message.info(this.$t('payrollTemplate.noChooseFile'))
            // }
        },
        onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch('payroll/payrollTemplate/destroy', record.template_id)
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'REPORT CUSTOMIZATION',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('payroll/payrollTemplate/RESET_STATE')
        next()
    }
}
</script>
